import React from "react";
import { Link } from "react-router-dom";

function MinecraftButton(props) {
  return (
    <div className="minecraft-button">
      {props.menuitem.external ? (
        <a
          href={props.menuitem.link}
          target="_blank"
          rel="nooperner noreferrer"
        >
          <div className="button-text">{props.menuitem.name}</div>
        </a>
      ) : (
        <Link to={props.menuitem.link} >
          <div className="button-text">{props.menuitem.name}</div>
        </Link>
      )}
    </div>
  );
}

export default MinecraftButton;
