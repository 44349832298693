import axios from "axios";

export default axios.create({
    headers: {
        "Content-Type": "application/json;charset=utf-8",
        // "api_key": `wHY4+.9g_jUePB52lNTfuHUr-TC`,
        "Accept": "application/json",
    },
    baseURL: `https://skocraft.se/api`,
});
