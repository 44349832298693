import React from "react";
import { Outlet } from "react-router-dom";
// import WarningBar from "./components/WarningBar";

function App() {
  return (
    <div className="page-container">
      <div className="fullscreen-container current" data-page="start">
        {/* <WarningBar /> */}
        <Outlet />
      </div>
    </div>
  );
}

export default App;
