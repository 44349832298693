import React from "react";
import SuccssesfulRegistration from "../components/RegisterForm/SuccssesfulRegistration";

function SuccssesfulRegistrationPage() {
  return (
    <>
      <SuccssesfulRegistration />
    </>
  );
}

export default SuccssesfulRegistrationPage;
