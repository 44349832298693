import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './TOSPopup.css';
import Markdown from 'markdown-to-jsx';

const TOSPopup = () => {

  const file_name = 'terms-of-service.md';
  const [tos, setTos] = useState('');

  useEffect(() => {
    import(`./${file_name}`)
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setTos(res))
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
  }
  );

  return (
    <Popup trigger={<button className="modal-btn">terms of service</button>} modal neste scrollbar>
      {close => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header">
            <h2>
              Terms of services
            </h2>
          </div>
          <div className="content">
            <Markdown>
              {tos}
            </Markdown>
          </div>
        </div>
      )}
    </Popup >
  )
}

export default TOSPopup