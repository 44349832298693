import React from 'react'
import './RegisterForm.css'
import { Link } from 'react-router-dom'
import "./RegisterForm.css";

const SuccssesfulRegistration = () => {

  
  return (
    <div className="information-container">
    <div className="register-form-backlink">
      <Link to="/">&times;</Link>
    </div>
    <center>
      <h1>Succssesful registration</h1>
    </center>
    <hr />
    <p>Please check your university email for activation link.</p>
    </div >
  )
}

export default SuccssesfulRegistration