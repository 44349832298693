import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../../api";
import validateFormData from "../../utils/formVaildator";
import "./RegisterForm.css";
import TOSPopup from '../TOSPopup/TOSPopup';
import { unstable_renderSubtreeIntoContainer } from "react-dom";

function RegisterForm() {
  const navigate = useNavigate();
  const [university, setUniversity] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [mojangUsername, setMojangUsername] = useState("");
  const [privateEmail, setPrivateEmail] = useState("");
  const [tosConsent, setConsent] = useState(false);
  const [rulesConsent, setRuleConsent] = useState(false);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    MojangUsername: '',
    UniversityUsername: '',
    University: '',
    Email: '',
    ToSConsent: '',
    RulesConsent: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(
        "MojangUsername: " + mojangUsername + '\n' +
        "UniversityUsername:" + universityName + '\n' +
        "University:" + university + '\n' + 
        "Email: " + privateEmail + '\n' + '\n' +
        "ERROR: " + errorMessages.University
      );
      let response = await API.post("/players", {
        MojangUsername: mojangUsername,
        UniversityUsername: universityName,
        University: university,
        Email: privateEmail,
        ToSConsent: tosConsent,
        RulesConsent: rulesConsent,
      });
      navigate("/succssesful");
    } catch (error) {
      if(error.response.status == '400') {
        setErrorMessages(error.response.data)
        console.log(errorMessages);

      }
      console.log(error.response.data);
      throw error;
    }
    // }
  };

  useEffect(() => {
    async function fetchData() {
      const response = await API.get("/universities");
      console.log(response);
      if (response.status === 200) {
        setUniversityOptions(response.data);
      }
    }
    fetchData().catch();
  }, []);

  return (
    <div>
      <form
        id="register-form"
        className="information-container"
        onSubmit={handleSubmit}
      >
        <div className="register-form-backlink">
          <Link to="/">&times;</Link>
        </div>
        <h2 className="center">Register Form</h2>
        <div className="form-element">
          <select
            name="university"
            id="university"
            className="form-element-field"
            onChange={(e) => setUniversity(e.target.value)}
            value={university.option}
          >
            <option value="" >--- Select University ---</option>
            {universityOptions.map((option, index) => {
              return (
                <option key={index} value={option.address}>
                  {option.name}
                </option>
              );
            })}
          </select>
          <div className="form-element-line"></div>
          <div className="form-element-message error">{errorMessages.University}</div>
          <label className="form-element-label">University</label>
        </div>
        <div className="form-element">
          <label
            name="university-address"
            className="form-element-field"
            style={{ width: "50%", float: "right" }}
            id="adress"
          >
            {university.length > 0 ? university : "@student.university.se"}
          </label>
          <input
            type="text"
            id="university-username-field"
            name="university-username"
            className="form-element-field"
            style={{ width: "50%" }}
            required
            value={universityName}
            onChange={(e) => setUniversityName(e.target.value)}
          ></input>
          <div className="form-element-line" style={{ width: "50%" }}></div>
          <div className="form-element-message error">{errorMessages.UniversityUsername}</div>
          <label
            htmlFor="university-username-field"
            className="form-element-label"
            style={{ width: "50%" }}
          >
            Student username
          </label>
        </div>
        <div className="form-element">
          <input
            type="text"
            id="mojang-field"
            name="mojang"
            className="form-element-field"
            required=""
            value={mojangUsername}
            onChange={(e) => setMojangUsername(e.target.value)}
          />
          <div className="form-element-line"></div>
          <label htmlFor="mojang-field" className="form-element-label">
            Mojang username
          </label>
          <div className="form-element-message error">{errorMessages.MojangUsername}</div>
        </div>
        <div className="form-element">
          <input
            type="text"
            id="email-field"
            name="email"
            className="form-element-field"
            onChange={(e) => setPrivateEmail(e.target.value)}
            value={privateEmail}
          />
          <div className="form-element-line"></div>
          <label htmlFor="email-field" className="form-element-label">
            Private email (optional)
          </label>
          <div className="form-element-message error">{errorMessages.Email}</div>
        </div>
        <div className="form-element">
          <label
            htmlFor="consent-checkbox"
            className="form-element-checkbox-label"
          >
            <input
              type="checkbox"
              id="consent-checkbox"
              className="form-element-checkbox"
              name="consent"
              required=""
              checked={tosConsent}
              onChange={(e) => setConsent(e.target.checked)}
            />
            <div className="checkbox"></div>
            <span id="consent-text">
              I consent to storage of my data and agree to the <TOSPopup />.
            </span>
          </label>
          <div className="form-element-message error error-checkbox">{errorMessages.ToSConsent}</div>
        </div>
        <div className="form-element">
          <label
            htmlFor="rules-checkbox"
            className="form-element-checkbox-label"
          >
            <input
              type="checkbox"
              id="rules-checkbox"
              className="form-element-checkbox"
              name="rules"
              required=""
              checked={rulesConsent}
              onChange={(e) => setRuleConsent(e.target.checked)}
            />
            <div className="checkbox"></div>
            <span id="consent-text">
              I accept the <a href="https://wiki.skocraft.se/index.php/Main_Page#General_rules">rules</a> of this server.
            </span>
          </label>
          <div className="form-element-message error error-checkbox">{errorMessages.RulesConsent}</div>
        </div>
        <div className="form-element center">
          <input
            type="submit"
            className="form-element-button"
            value="Register"
          />
        </div>
      </form>
    </div>
  );
}

export default RegisterForm;
