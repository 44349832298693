import React from "react";
import MinecraftButton from "./MinecraftButton";
import logo from "../assets/skocraft.png";

function Menu() {
  const menuItemList = [
    { name: "Register", link: "/register", external: false },
    {
      name: "Discord",
      link: "https://discord.com/invite/YfpZaXNUf6",
      external: true,
    },
    {
      name: "Wiki (Info & Rules)",
      link: "https://wiki.skocraft.se",
      external: true,
    },

  ];
  const menuItems = menuItemList.map((item, index) => {
    return <MinecraftButton key={index} menuitem={item} />;
  });
  return (
    <div className="menu">
      <div className="logo-container">
        <img src={logo} alt="Sköcraft" />
      </div>
      {menuItems}
    </div>
  );
}

export default Menu;
