import React from "react";
import RegisterForm from "../components/RegisterForm/RegisterForm";

function RegisterPage() {
  return (
    <>
      <RegisterForm />
    </>
  );
}

export default RegisterPage;
