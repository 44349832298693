import React from 'react'
import Menu from '../components/Menu'

function HomePage() {
  return (
    <>
        <Menu/>
    </>
  )
}

export default HomePage