import React from 'react'
import { useLocation } from 'react-router-dom'
import API from "../api";
import { useEffect, useState } from 'react'


const ActivationPage = () => {
  const [header, setHeader] = useState('Processing');
  const [text, setText] = useState('Your account is being activated please wait.');

  // let issuesText = 'If you experience any issues please consult our Wiki or reach out to us in the ask-sköcraft chat on our Discord.'
  
  
  const search = useLocation().search;
  const activationcode = new URLSearchParams(search).get('code');
  useEffect(() => {
    async function fetchData() {
      let response = await API.post("/confirm-email", {
        activationCode: activationcode,
      }).then(response => {
        setHeader(response.data.header);
        setText(response.data.message)   
      }).catch(error => {
        setHeader(error.response.data.header)
        setText(error.response.data.message)
        // if (error.response.status === 400) {
        //       setHeader('Invalid activation code provided');
        //       setText('Please contact us on our ask-sköcraft chat on our Discord.')
        //     }
        //     else if (error.response.status === 404) {
        //       setHeader('Account already activated')
        //       setText('This account has already been activated. ' + issuesText)
        //     }
        //     else {
        //       setHeader('Something went wrong!')
        //       setText('Please contact us on our ask-sköcraft chat on our Discord.')
        //     }
      });
    }
    fetchData().catch();
  }, []);

  
  
  return (
    <div className="information-container">
    <center>
      <h1>{header}</h1>
    </center>
    <hr />
    <p>{text}</p>
    </div >
  )
}

export default ActivationPage